import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../../images/logos/logo_261.png';
import './NavigationBar.css';

const NavigationBar = () => {
	return (
		<div className="navigation-bar">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<Navbar expand="lg">
							<Navbar.Brand href="/">
								<img src={logo} className="img-fluid" alt="" />
							</Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="ml-auto">
									<Link to="/" className="nav-link">
										Home
									</Link>
									<a href="#services" className="nav-link">
										Services
									</a>
									<a href="/#stacks" className="nav-link">
										Stacks
									</a>
									<a href="#contact" className="nav-link">
										Contact Us
									</a>
									{/* <Link to="/login" className="nav-link">Login</Link> */}
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</div>
				</div>
			</div>
		</div>
	);
};
// #0b436d #1069ad ##0a2940 ##0f2b40
// #1d2328  #080e12
export default NavigationBar;
