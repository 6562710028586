import React from 'react';
import ServiceCard from '../ServiceCard/ServiceCard';
import './Service.css';

const Service = () => {
	const services = [
		{
			name: 'UI/UX',
			body: '',
			image:
				'https://i.pinimg.com/originals/a7/e0/44/a7e044a1cd1a30b6b6f6294061ad6d12.jpg',
		},
		{
			name: 'IT Consulting',
			body: '',
			image:
				'https://img.freepik.com/free-photo/coding-man_1098-18084.jpg?w=996&t=st=1668437957~exp=1668438557~hmac=a7d057f2130f0b250b835d4e8b755ae3b733fdb41f5b768bd1541c79d9633db1',
		},
		{
			name: 'Outsourcing',
			body: '',
			image:
				'https://img.freepik.com/free-photo/young-male-web-designers-working-computer_1303-19453.jpg?w=996&t=st=1668438049~exp=1668438649~hmac=04b1fd7b1b4e963572e8d80a0d8fc3c682026a9b31ffb06e204f932e83902c26',
		},
		{
			name: 'Web Development',
			body: '',
			image:
				'https://img.freepik.com/premium-photo/professional-developer-programmer-working-software-website-design-coding-technology-writing-codes-database-company-office-global-cyber-connection-technology_122498-189.jpg?w=1060',
		},
		{
			name: 'Blockchain',
			body: '',
			image:
				'https://img.freepik.com/free-photo/hand-pointing-currency-blockchain-technology-background_53876-102966.jpg?w=996&t=st=1668438178~exp=1668438778~hmac=cd244fa126d6c2c6796fc05966f64ad02505f6e725bd9b100686d7a11bb118d5',
		},
		{
			name: 'IT Trainings',
			body: '',
			image:
				'https://img.freepik.com/free-photo/asia-businesspeople-using-desktop-talk-colleagues-discussing-business-brainstorm-about-plan-video-call-meeting-new-normal-office_7861-3180.jpg?w=1380&t=st=1668438278~exp=1668438878~hmac=af2a9442473e9d092e87736ba5043b2879fda1f5803498fdefa881b24c77e859',
		},
	];
	return (
		<div className="service-area" id="services">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="common-heading">
							<h3>Providing high quality services</h3>
						</div>
					</div>
				</div>

				<div className="row mt-5 pt-3">
					{services.map((service) => (
						<ServiceCard key={service.name} service={service} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Service;
