import React from 'react';

const ServiceCard = ({ service }) => {
	const { name, body, image, key } = service;
	return (
		<div className="col-lg-4 col-md-6" key={key}>
			{/* <Link to={`/dashboard/order/${name}`}> */}
			<div
				className="service-card text-center"
				style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}
			>
				{/* <img src={image} className="img-fluid" alt="" /> */}
				<h4 className="mt-4">{name}</h4>
				<p>{body}</p>
			</div>
			{/* </Link> */}
		</div>
	);
};

export default ServiceCard;
