import React from 'react';
import banner from '../../../images/tech4.png';
import './Banner.css';

const Banner = () => {
	return (
		<div className="banner-area mt-sm-4 mt-md-5">
			<div className="container">
				<div className="row">
					<div className="col-xl-5 col-lg-6">
						<div className="banner-text mt-lg-4">
							<h2>Developing solutions for the future</h2>
							<p>
								We are providing high quality solutions tailored to your company
								needs. Having a wide spectrum of services, we can provide
								consultants to collaborate with your team, turnkey solutions or
								IT trainings
							</p>
							<a href="#contact">Let's talk</a>
						</div>
					</div>
					<div className="offset-xl-1 col-xl-6 col-lg-6">
						<div className="banner-img d-md-block d-none">
							<img src={banner} className="banner-image" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
