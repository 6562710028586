import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './Footer.css';

const SUCCESS_MSG = 'Thank you for your message!';
const CHECK_MSG =
	'Please accept the Privacy Policy and the Terms and Conditions';

const Footer = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [checkbox, setCheckbox] = useState(false);
	const [feedback, setFeedback] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		if (checkbox) {
			const values = { name, email, message };

			emailjs.send(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				values,
				process.env.REACT_APP_PUBLIC_KEY
			);
			setFeedback(SUCCESS_MSG);
		} else {
			setFeedback(CHECK_MSG);
		}
	};
	return (
		<div id="contact" className="bottom">
			<div className="container">
				<div className="row">
					<div className="col-md-5">
						<div className="footer-text">
							<h2 className="mb-4">Let’s get in touch</h2>
							<p>
								Do not hesitate to contact us, if you have any questions or
								requests. We will get back to you as soon as possible.
							</p>
						</div>
					</div>
					<div className="col-md-7">
						<div className="footer-form">
							<form action="">
								<input
									type="text"
									placeholder="Your email address"
									onChange={(e) => setEmail(e.target.value)}
								/>
								<input
									type="text"
									placeholder="Your name/company’s name"
									onChange={(e) => setName(e.target.value)}
								/>
								<textarea
									type="text"
									placeholder="Your message"
									onChange={(e) => setMessage(e.target.value)}
								/>
								<input
									type="checkbox"
									id="checkbox"
									name="checkbox"
									value={checkbox}
									onChange={(e) => setCheckbox(!checkbox)}
								/>
								<label htmlFor="checkbox">
									{' '}
									I have read and accept the{' '}
									<a
										href={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>{' '}
									and the{' '}
									<a
										href={process.env.PUBLIC_URL + '/terms-and-conditions.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Terms and Conditions
									</a>
								</label>
								<br></br>
								<button type="submit" onClick={(e) => handleSubmit(e)}>
									Send
								</button>
								<p
									className={
										feedback.includes(SUCCESS_MSG) ? 'success' : 'fail'
									}
								>
									{feedback}
								</p>
							</form>
						</div>
					</div>
				</div>
				<div className="row">
					<br />
					<br />
					<br />
				</div>
			</div>
			<div className="footer-area">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{/* <div className="copyright-right-area text-right">
                            <p className="mb-0 mt-5 pt-3">office@<span style={{display: 'none'}}>null</span>coden.ro</p>
                            <p> (+40) 775 238 558</p>
                            </div> */}
							<div className="copyright-area text-center">
								<p className="mb-0 mt-5 pt-3">
									&copy; {new Date().getFullYear()} Coden IT Consulting SRL
								</p>
								<p className="footer-subtitle" style={{ fontSize: '14px' }}>
									<a href="mailto:office@coden.ro">
										office@<span style={{ display: 'none' }}>null</span>coden.ro
									</a>{' '}
									|{' '}
									<a href="tel:+40775238558">
										(+40)<span style={{ display: 'none' }}>null</span> 775 238
										558
									</a>{' '}
									|{' '}
									<a
										href={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>{' '}
									|{' '}
									<a
										href={process.env.PUBLIC_URL + '/terms-and-conditions.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Terms and Conditions
									</a>
								</p>
								<span className="footer-subtitle">
									Cyber Insurance Policy No. ESL0139523012
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
