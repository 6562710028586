import React from 'react';
import reactLogo from '../../../images/logos/react.png';
import nextLogo from '../../../images/logos/next.png';
import nodeLogo from '../../../images/logos/node.png';
import nestjsLogo from '../../../images/logos/nestjs.png';
import solLogo from '../../../images/logos/solidity.png';
import './BrandLogo.css';

const BrandLogo = () => {
	return (
		<div className="brand-logos">
			<div className="container">
				<div className="row d-flex align-items-center">
					<div className="offset-lg-1 col-lg-2 col-md-3 col-4 text-center">
						<img src={reactLogo} className="img-fluid" alt="" />
					</div>
					<div className="col-lg-2 col-md-2 col-4 text-center">
						<img src={nextLogo} className="img-fluid" alt="" />
					</div>
					<div className="col-lg-2 col-md-2 col-4 text-center">
						<img src={nodeLogo} className="img-fluid" alt="" />
					</div>
					<div className="col-lg-2 col-md-2 col-4 text-center">
						<img src={nestjsLogo} className="img-fluid" alt="" />
					</div>
					<div className="col-lg-2 col-md-3 col-4 text-center">
						<img src={solLogo} className="img-fluid" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BrandLogo;
